/*
Theme Name: Theme ATMCT
Author: Mélanie Klein, Graphic Plugin
Author URI: http://www.graphic-plugin.be
Description: Theme wordpress pour la page d'inscription au Andre Tafi Mapei Cycling Tour

http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1; 
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none; 
}
input[type="button"], input[type="submit"], input[type="reset"], input[type="file"], webkit-file-upload-button, button {
    border-radius: 0;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
/*------------------------------FIN RESET MEYER------------------------------*/

@bleu: #0061a8;
@noir: #000000;
@blanc: #ffffff; 

@col6: 49.143em;
@col5: 40.571em;
@col4 : 32em;
@col3 : 23.429em;

@font: brandon-grotesque, sans-serif;

/*12x88x32*/

/*------CLEARFIX-----*/
.clearfix() {
    .cf:before,
    .cf:after {
        content: " "; /* 1 */
        display: table; /* 2 */
    }

    .cf:after {
        clear: both;
    }

    .cf {
        clear:both;
    }

    /**
     * For IE 6/7 only
     * Include this rule to trigger hasLayout and contain floats.
     */
    .cf {
        *zoom: 1;
    }
}

/*------STYLES GENERAUX------*/

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

body {
    .clearfix();
    font-family: @font;
    font-size: 14px;
    color: @noir;
    font-weight: 300;
}

.outliner {
    display: none;
} 

.content {
    width: 100.571em;
    margin: 0 auto;
}

header {
    background-image: url('imgs/XT4A1301.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    position: relative;
    min-height: 840px;
}  

.cancel {
    position: absolute;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10%;
    padding: 2em;
    background-color: rgba(0, 28, 48, 0.8);
    p { 
        color: white;
        font-size: 22px;
        text-align: center;
        line-height: 1.4em;
    }
} 

.langues {
    position: absolute;
    top: 2em;
    right: 10%;
    li {
        display: inline-block;
    }
    span {
        font-size: 2.143em;
        color: @blanc; 
        font-weight: 700;
    }
    a {
        font-size: 2.143em;
        color: @blanc;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 300;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        &:hover {
            opacity: 0.3;
        }
    } 
    #menu-item-13 { 
        &:after {
            content: '/';
            margin: 0 0.3em;
            font-weight: 700;
            font-size: 2.143em;
            color: @blanc;
        }
    }
    .current-menu-item a {
        font-weight: 700;
    }
} 

.logo {
    width: 42.857em;
    position: absolute;
    top: 22em;
    left: 0;
    img {
        width: 100%;
        height: auto;
    }
}

h2 {
    font-size: 3.8em;
    width: 20em; 
    font-weight: 300;
    text-transform: uppercase;
    color: @blanc;
    text-align: right;
    position: absolute;
    bottom: 10%;
    right: 10%;
    padding-right: 1.75em;
    padding-bottom: 3em; 
    text-shadow: 0 0 15px black;
    em {
        font-weight: 900;
    }
    strong {
        display: block;
        font-weight: 900;
    }
    &:after {
        font-size: 14px;
        content: '';
        display: inline-block;
        width: 32.857em;
        height: 32.214em;
        background: url('imgs/h2carre.png') no-repeat;
        background-size: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

.title {
    position: absolute;
    left: 11em;
}

h3 {
    font-size: 4.286em;
    color: @blanc;
    font-weight: 900;
    font-style: italic;
}

.date {
    text-transform: uppercase;
    font-weight: 300;
    text-transform: uppercase;
    
    color: @blanc;
}

.p-fr {
    .date {
       font-size: 3em; 
    }
}

.p-nl {
    .date {
        font-size: 3em;
    }
} 

.scrollbutton {
    width: 100%;
    position: absolute;
    bottom: 3%;
    left: 0;
    img {
        display: block;
        margin: 0 auto;
    }
}

.page {
    padding-top: 5em;
    section {
        margin-bottom: 5em;
    }
}

.intro {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    max-width: 850px;
    .intro-bloc {
        background-color: @bleu;
        padding: 2em 2em 1em;
        color: white;
        margin-bottom: 2em;
    }
    strong {
        font-weight: 700;
    }
    h3 {
        margin-top: 0.8em;
        color: @bleu;
        text-transform: uppercase;
        font-size: 2em;
        font-weight: 900;
    }
    .uci-partner {
        text-align:center;
        img {
            width: 150px;
            height: auto;
        }
        p {
            text-align: left;
        }
    }

    .conclusion {
        margin-top: 4em;
        text-transform: uppercase;
    }

    p {
        font-size: 1.3em;
        line-height: 1.4em;
        margin-bottom: 1em;
    }
    li p {
        text-transform: uppercase;
        font-weight: 500;
    }
    li ul li h4 {
        line-height: 1.4em;
        text-transform: uppercase;
        font-size: 1.5em;
        font-weight: 900;
    }
    .choix-formule {
        margin-bottom: 4em;
        border: 6px solid @bleu;
        padding-bottom: 1.5em; 
        .list2 {
            h3,h4 {
                font-weight: normal;
            }
        }
        h3 {
            margin-bottom: 0.3em;
        }
        span {
            color: @bleu;
            display: inline-block;
            margin: 0 0.3em;
            font-size: 2.5em;
            font-weight: 900;
            position: relative;
        }
        p {
            text-transform: none;
            margin-bottom: 0;
        }
        ul {
            margin-bottom: 0.5em;
        }
        li {
            display: inline-block;
        }
    }
    em {
        font-style: italic;
    }
    a {
        color: @bleu;
        font-weight: 500;
        transition:  all 0.5s;
        &:hover {
            color: #000000;
        }
    }
}  

.start {
    .location {
        float: left;
        margin-top: 4em;
        width: @col6;
        &:before {
            font-size: 14px;
            content: '';
            display: inline-block;
            width: 15em;
            height: 16.143em;
            background: url('imgs/start-icon.png') no-repeat;
            background-size: 100%; 
            float: left;
            margin-right: 2em;
        }
    }

    h3 {
        margin-top: 2.2em;
        color: @bleu;
        text-transform: uppercase;
        font-size: 3.571em;
        font-weight: 900;
    }
    p {
        font-size: 1.786em;
        line-height: 1.4em;
    }
    strong {
        font-weight: 700;
    }
    .at {
        float: right;
        width: @col3;
        p {
            font-size: 1.571em;
            text-transform: uppercase;
            color: @bleu;
            font-weight: 700;
            text-align: center;
        }
    }
}

.distance {
    p {
        font-size: 2.214em;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
    }
    strong {
        display: block;
        font-size: 1.483em;
    }
}


.form {
    position: relative;
    padding: 3em;
    border: 6px solid @bleu;
    .nf-response-msg {
        p {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 1em;
            text-align: center;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        i {
            font-style: italic;
        }
    }
    .nf-field-label label {
        font-size: 1.286em;
        text-transform: uppercase;
        font-weight: 300;
        &:before {
            font-size: 14px;
            content: '';
            width: 2.143em;
            height: 0.143em;
            margin-right: 0.5em;
            background-color: @bleu;
            display: inline-block;
        }
    }
    .nf-field-container {
        margin-bottom: 3em;
    }
    .nf-field-container .nf-after-field {
        position: absolute;
        top: 4.5em;
        left: 0;
        width: 100%;
    }
    .ninja-forms-req-symbol {
        color: @bleu;
    }
    .nf-field-element input, 
    .nf-field-element select, 
    .nf-field-element textarea {
        padding: 0.5em 0.8em;
        font-size: 1.143em;
        font-family: @font;
    }
    .nf-field-element select {
        font-weight: 900;
        text-transform: uppercase;
        font-size: 1.286em;
        color: @bleu;
        height: 2.5em;
        border-radius: 0;
        -webkit-appearance: none;
        background-color: white;
        background-image: url('imgs/fleche.png');
        background-position: right center;
        background-repeat: no-repeat;
    }
    .w30 {
        width: 31%;
    }
    .w70 {
        width: 65%;
    } 
    .w15 {
        width: 13.9%;
    }
    .nf-field-container {
        display: inline-block;
    }
    #nf-field-30-container, #nf-field-35-container, #nf-field-32-container, #nf-field-44-container, #nf-field-49-container, #nf-field-46-container  {
        margin: 0 3%;
    } 
    #nf-field-36-container, #nf-field-29-container, #nf-field-50-container, #nf-field-43-container {
        margin-right: 3%;
    }
    .nf-form-fields-required {
        font-style: italic;
        font-size: 1.214em;
        margin-bottom: 2em;
        text-align: right;
    }
    .submitbutton {
        position: absolute;
        bottom: -5em;
        left: 0;
        width: 100%;
    }
    #nf-field-39, #nf-field-53 {
        color: @blanc;
        background-color: @noir;
        font-size: 1.786em;
        text-transform: uppercase;
        font-weight: 900;
        padding: 0.5em 1.5em;
        display: block;
        margin: 0 auto;
        border-left: 1.786em solid white;
        border-right: 1.786em solid white;
        border-top: 0.357em solid white;
        border-bottom: 0.357em solid white;
        cursor: pointer;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -o-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s; 
        &:hover {
            background-color: @bleu;
        } 

    }
}

#nf-form-2-cont, #nf-form-3-cont {
    .nf-after-field {
        position: absolute;
        width: 100%;
    }
    .nf-response-msg {
        p {
            font-size: 20px;
        }
    }
} 

#nf-field-42-container, #nf-field-55-container {
    .list-radio-wrap .nf-field-element li {
        font-size: 18px;
        display: inline-block;
        width: 17em;
        margin: 0.7em 0 0 0 !important;
    }
    .list-checkbox-wrap .nf-field-element li input, .list-radio-wrap .nf-field-element li input {
        margin-right: 25px;
    }

}

    form input[type="radio"] {
        width: auto;
        position: relative;
        left: 0;
        margin-right: 1.5em;
        margin-top: 1.5em;
        &:before {
            content: '';
            position: absolute;
            top: -0.5em;
            left: 0;
            width: 30px;
            height: 30px;
            visibility: visible;
            cursor: pointer;
            background-color: #ffffff;
            border-radius: 30px;
            border: 2px solid @bleu;
        }
        &:after {
            opacity: 0;
            content: '';
            position: absolute;
            width: 0.8em;
            height: 0.5em;
            background: transparent;
            top: -0.1em;
            left: 0.35em;
            border: 5px solid @bleu;
            border-top: none;
            border-right: none;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
        } 
    }
    form input[type="radio"]:checked:after {
        opacity: 1;
        visibility: visible; 
    }

.photos {
    position: relative;
    width: 100%;
    margin-top: 9em;
    &:before {
        content: '';
        font-size: 14px;
        display: inline-block;
        background: url('imgs/velo.png') no-repeat;
        position: absolute;
        top: -6.8em;
        right: 8em;
        background-size: 150px;
        width: 152px;
        height: 95px;  
    }  
    img {
        width: 100%;
        height: auto;
    }
}

.copyright {
    margin: 1em 0;
    text-align: right;
    p {
        font-size: 0.929em;
    }
    a {
        color: @noir;
        &:hover {
            color: @bleu;
        }
    }
}

@media screen and (max-width: 1730px) and (max-height: 805px) {
    .logo {
        top: 17em;
    }
}

@media screen and (max-width: 1420px) {

    .content {
        width: 89.286em;
    }
    .logo {
        left: -7%;
    }
    h2 {
        right: 5%;
    }
    .langues {
        right: 5%;
    }
    .distance img {
        width: 100%;
        height: auto;
    }
    .form .nf-field-label label {
        font-size: 1.17em;
    }
}


@media screen and (max-width: 1270px) {
    header {
        min-height: 750px;
    } 
    .content {
        width: 67.857em;
    }
    .logo {
        top: 18em;
    }
    h2 {
        font-size: 3.4em;
        padding-right: 1.3em;
        padding-bottom: 3.1em;
        bottom: 13%;
        &:after {
            width: 29.857em;
            height: 29.4em; 
        } 
    }
    .p-nl {
        h2 {
            width: 17em;
        }
    }
    .start .location {
        width: 44.8em;
        &:before {
            width: 12em;
        }
    }
    .start .at {
        width: 21.5em;
    }
    .start p {
        font-size: 1.7em;
    }
    .start h3 {
        margin-top: 1.4em;
    }
    .distance p {
        font-size: 1.8em;
    }
    .form .nf-field-label label {
        font-size: 1em;
        &:before {
            width: 1.143em;
        }
    }
    .start .at p {
        font-size: 1.3em;
    }
    .form {
        margin-bottom: 7em;
    }
    .form #nf-field-39, .form #nf-field-53 {
        font-size: 1.6em; 
    } 
    #nf-field-42-container .list-radio-wrap .nf-field-element li, #nf-field-55-container .list-radio-wrap .nf-field-element li {
        font-size: 16px; 
    }
    form input[type="radio"]:after {
        top: 0;
        left: .4em;
    } 
}

@media screen and (max-width: 1270px) and (max-height: 860px) {
    .logo {
        top: 12em;
    }
}

@media screen and (max-width: 970px) {
    header {
        min-height: 690px; 
    } 
    .cancel {
        width: 80%;
        p {
            font-size: 18px;
        }
    }
    .content {
        width: 53.571em;
    }
    .logo {
        width: 36em;
        left: -11%;
    }
    h3 {
        font-size: 3.4em;
    }
    .p-fr .date {
        font-size: 2.15em;
    }
    .p-nl .date {
        font-size: 2.15em;
    }
    h2 {
        padding-bottom: 3.1em;
        font-size: 2.5em;
        width: 15em;
        &:after {
            width: 25.857em;
            height: 25.4em;
        }
    }
    .p-nl {
        h2 {
            width: 14em;
            padding-bottom: 2.5em;
        }
    }
    .langues a {
        font-size: 1.8em;
    }
    .langues #menu-item-15:after {
        font-size: 2em;
    }
    .start .location {
        width: 35.4em;
        margin-top: 0em;
    }
    .start .location:before {
        width: 10em;
        margin-right: 1.3em;
        height: 11.143em;
        margin-top: 1.2em;
    }
    .start p {
        font-size: 1.35em;
    }
    .start h3 {
        font-size: 2.8em;
        margin-top: 2em;
    }
    .start .at {
        width: 17.5em;
        img {
            width: 100%;
            height: auto;
        }
    }
    .form .w15 {
        width: 13.8%;
    } 
    .photos:before {
        right: 3em; 
    }
}

@media screen and (max-width: 840px) {
    .logo {
        top: 1em;
    }
}

@media screen and (max-width: 760px) {
    header {
        min-height: 560px;
    } 
    .content {
        width: 90%;
    }
    .page {
        padding-top: 3em;
    }
    .intro {
        width: 100%;
    }
    .intro h3 {
        font-size: 1.6em;
    }
    .intro p {
        font-size: 1.2em;
    }
    .intro li ul li h4 {
        font-size: 1.2em;
    }
    .intro .choix-formule span {
        font-size: 1.8em;
    }
    .logo {
        width: 28em;
    }
    h2 {
        font-size: 2em;
    }
    h2:after {
        width: 20em;
        height: 20em;
    }
    .p-nl {
        h2 {
            padding-bottom: 2.8em;
        }
    }
    .title {
        left: 26%;
    }
    h3 {
        font-size: 2.8em;
    }
    .p-nl .date {
        font-size: 1.75em;
    }
    .p-fr .date {
        font-size: 1.75em;
    }
    .start .location {
        width: 100%;
        display: block;
        float: none;
    }
    .start .location:before {
        display: block;
        float: none;
        margin: 0 auto;
    }
    .start h3 {
        margin-top: 1em;
        text-align: center;
    }
    .start p {
        text-align: center;
    }
    .start .at {
        margin: 2em auto 0;
        display: block;
        float: none;
    }
    .distance p {
        font-size: 1.5em;
        margin-bottom: 2em;
    }
    .page .form {
        padding: 2em 2em 4em;
        border: 4px solid #0061a8;
    }
    .form .w30, .form .w15 {
        width: 48%;
    } 
    .form .w70 {
        width: 100%;
    }
    .form #nf-field-30-container, .form #nf-field-34-container, .form #nf-field-36-container, .form #nf-field-37-container, .form #nf-field-41-container {
        margin: 0 0 25px 3%;
    }
    .form #nf-field-35-container, .form #nf-field-32-container {
        margin: 0 0 25px 0;
    }
    .form #nf-field-29-container {
        width: 100%;
        margin-right: 0;
    } 

    .nf-error-wrap { 
        position: absolute;
    }
    .form #nf-field-15-container, .form #nf-field-26-container  {
        position: absolute;
    }
    .form #nf-field-15, .form #nf-field-26 {
        border-left: 0.786em solid white;
        border-right: 0.786em solid white;
    }
    #nf-field-42-container .list-radio-wrap .nf-field-element li, #nf-field-55-container .list-radio-wrap .nf-field-element li {
        margin: .7em 0 0.5em 0 !important;
    }
    .photos {
        margin-top: 11em;
    }
}

@media screen and (max-width: 550px) { 
    header {
        min-height: 470px;
    }
    h2 {
        right: 8%;
        font-size: 1.8em;
        width: 16em;
        padding-bottom: 2.5em;
        padding-right: 2.3em;
    }
    h2:after {
        width: 15em;
        height: 15em;
    } 
    .p-nl {
        h2 {
            width: 15em;
            padding-bottom: 3.2em;
        }
    }
    .langues {
        right: 8%;
        top: 1.5em;
    }
    .logo {
        left: -15%;
    }
    .scrollbutton img {
        width: 5em;
    }
    .start .location:before {
        width: 7em;
        height: 7.7em;
    }
    .start h3 { 
        font-size: 2em;
        margin-top: 0.5em;
    }
    .start p {
        font-size: 1.3em;
    }
    .page section {
        margin-bottom: 3em;
    }
    .intro .uci-partner img {
        float: none;
        border-right: 0;
        margin-bottom: 1em;
    }
    .intro .uci-partner p {
        text-align: center;
    }
    .copyright p {
        font-size: 0.85em;
    }
    .page .form {
        margin-bottom: 5em;
    }
    .form #nf-field-15, .form #nf-field-26 {
        font-size: 1.4em;
    }
}   

@media screen and (max-width: 480px) {
    header {
        min-height: 430px;
    }
    .logo {
        width: 23em;
        top: 4em;
        left: -17%;
    }
    h3 {
        font-size: 2.35em;
    }
    .p-nl .date {
        font-size: 1.5em;
    }
    .p-fr .date {
        font-size: 1.5em;
    }
    .langues #menu-item-15:after {
        font-size: 1.5em;
    }
    h2 {
        width: 15em;
        font-size: 1.25em;
        line-height: 1.3em;
        padding-bottom: 2.85em;
        padding-right: 1.3em; 
    }
    h2:after {
        width: 12.5em;
        height: 12.4em;
    }
    .scrollbutton img {
        width: 4em;
    }
    .start p {
        font-size: 1.1em;
    }
    .start .at p {
        font-size: 1.2em;
    }
    .distance p {
        font-size: 1.2em;
    }
    .form .w30, .form .w15 {
        width: 100%;
    }
    .form #nf-field-30-container, .form #nf-field-34-container, .form #nf-field-36-container, .form #nf-field-37-container, .form #nf-field-41-container {
        margin: 0 0 25px 0;
    }
    #nf-field-42-container .list-radio-wrap .nf-field-element li, #nf-field-55-container .list-radio-wrap .nf-field-element li {
        width: 100%;
    }
    .photos {
        overflow-x: clip;
        img {
            width: 170%;
        }
    }

} 

@media screen and (max-width: 285px) {
    header {
        min-height: 355px;
    }
    body {
        min-width: 220px;
    }
    .logo {
        width: 17em;
        top: 4em;
    } 
    h2 {
        font-size: 1.1em;
        padding-bottom: 2.5em;
        padding-right: 1em;
        width:  11em; 
        &:after {
            width: 10.5em;
            height: 10.4em;
        }
    }
    h3 {
        font-size: 1.8em; 
    }
    .p-fr .date {
        font-size: 1.12em;
    }
    .p-nl .date {
        font-size: 1.12em;
    }
    .p-nl h2 {
        width: 13em;
    }
    .start .at {
        width: 100%;
    }
    .page .form {
        padding: 1em;
    }
    .form .nf-form-fields-required {
        font-size: 1em;
        margin-bottom: 1em;
    }
    .form #nf-field-15, .form #nf-field-26 {
        font-size: 1em;
    }
    .form .nf-field-element input, .form .nf-field-element select, .form .nf-field-element textarea {
        font-size: 1em;
    }
    .form .submitbutton {
        bottom: -3.8em;
    }


}



